<script setup lang="ts">
import { useHelpers } from '@/Composables/helpers'
import ApplicationHead from '@/Components/Layout/ApplicationHead.vue'
import HeroSlider from './Partials/HeroSlider.vue'
import OurFeatures from './Partials/OurFeatures.vue'
import FeaturedCategories from './Partials/FeaturedCategories.vue'
import NewArrivals from './Partials/NewArrivals.vue'
import MetalCategories from './Partials/MetalCategories.vue'
import BestSellers from './Partials/BestSellers.vue'
import Outlet from './Partials/Outlet.vue'
import LatestReviews from './Partials/LatestReviews.vue'
import LatestPosts from './Partials/LatestPosts.vue'
import { BlogPost } from '@interfaces/blog-post.interface'

defineProps<{
    newArrivals: Array<any>
    bestSellers: Array<any>
    reviews: Array<any>
    posts: Array<BlogPost>
}>()

const { asset } = useHelpers()
</script>

<template>
    <div>
        <ApplicationHead :description="$t('pages.home.meta.description')">
            <meta
                property="og:type"
                content="website"
            >
            <meta
                property="og:image"
                :content="asset('images/social-logo.png')"
            >
        </ApplicationHead>

        <HeroSlider />
        <OurFeatures />
        <FeaturedCategories />
        <NewArrivals :products="newArrivals" />
        <MetalCategories />
        <BestSellers :products="bestSellers" />
        <Outlet />
        <LatestReviews :reviews="reviews" />
        <LatestPosts :posts="posts" />
    </div>
</template>
